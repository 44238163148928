import React from "react";
import third_ipad from "../../../assets/third section/Ipad.png";
import "./style.css"

const AboutUs = ({aboutUsRef}) => {
  return (
    <div>
      <div className="about-us-main-container" ref={aboutUsRef}>
        <div className="about-us-container">
          <div className="about-us-content-wrap" data-aos="fade-down">
            {/* <div className="about-us-hd-wrap">
              <p className="about-us-hd">About Us</p>
            </div> */}
            <div className="about-us-content-hd-wrap">
              <p className="about-us-content-hd">Al-Powered Stories</p>
            </div>
            <div className="about-us-text-wrap">
              <p className="about-us-text">
                Connect your kids with stories from Al-powered platform with
                illustrations and feedback that helps to improve their reading
                and creativity skills
              </p>
              <button className="learn-more-button">Learn More</button>
            </div>
          </div>
          <div className="about-us-img-container" data-aos="fade-up">
            <div className="ipad-img-container">
              <img src={third_ipad} className="ipad-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
