import React, { useState } from "react";
import tenth_peoples from "../../../assets/testimonal/review_images.png";
import tenth_yellow_circle from "../../../assets/testimonal/cicle-yellow.png";
import tenth_dot from "../../../assets/testimonal/card-bg-dot.png";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import "./style.css";

const Footer = ({ onLink }) => {
  const currentYear = new Date().getFullYear();
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  // const [animationLeft, setAnimationLeft] = useState(false);
  // const [animationRight, setAnimationRight] = useState(false);
  const lastIndex = testimonials.length - 1;
  const handleLeftClick = () => {
    if (currentTestimonialIndex <= 0) {
      return;
    } else {
      setCurrentTestimonialIndex(currentTestimonialIndex - 1);
      // setAnimationLeft(true);
    }
  };

  const handleRightClick = () => {
    console.log("currentTestimonialIndex", currentTestimonialIndex);
    if (currentTestimonialIndex >= lastIndex) {
      return;
    } else {
      setCurrentTestimonialIndex(currentTestimonialIndex + 1);
      // setAnimationRight(true);
    }
  };
  console.log("currentTestimonialIndex", currentTestimonialIndex);

  return (
    <div className="footer-wrap" data-aos="fade-down">
      <div className="testimonial-container">
        <div className="testimonial-yellow-donut-wrap">
          <img
            className="testimonial-yellow-donut"
            src={tenth_yellow_circle}
            alt="yellow-donut"
          />
        </div>
        <div className="testimonial-wrap">
          <div className="bg-imgs-and-content-container">
            <div className="testimonial-hd-wrap">
              {/* <p className="testimonial-hd">TESTIMONIALS</p> */}
              <p className="testimonial-content-hd">What people say</p>
            </div>
            <img
              src={tenth_peoples}
              className="review-people-bg-img"
              alt="review-people"
            />
            <img
              className="white-dotted-bg-img"
              src={tenth_dot}
              alt="white-dotted-bg"
            />
            <div className="testimonial-card-main-container">
              <div className="testimonial-card-container">
                <div className="testimonial-card-wrap">
                  <div className="testimonial-text-container">
                    <div className="comma-bg-img">
                      <div className="testimonial-text-wrap">
                        {/* ${animationLeft ? "animation-left" : ""}
                          ${animationRight ? "animation" : ""} */}
                        <p className="testimonial-text">
                          {testimonials[currentTestimonialIndex].text}
                        </p>
                      </div>
                      <div>
                        <p className="review-person-name">
                          {testimonials[currentTestimonialIndex].author}
                        </p>
                        <div className="arrow-icons-container">
                          <button
                            className="left-arrow-icon-wrap"
                            onClick={handleLeftClick}
                          >
                            <FaArrowLeft className="left-arrow-icon" />
                          </button>

                          <button
                            className="right-arrow-icon-wrap"
                            onClick={handleRightClick}
                          >
                            <FaArrowRight className="right-arrow-icon" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-content-container">
        <div className="footer-content-hd-wrap">
          <p className="footer-content-hd">FictionGenie</p>
        </div>
        <div className="footer-links-wrap">
          <p onClick={() => onLink("headerRef")} className="footer-link">
            Home
          </p>
          <p onClick={() => onLink("aboutUsRef")} className="footer-link">
            About us
          </p>
          <p onClick={() => onLink("categoriesRef")} className="footer-link">
            Categories
          </p>
          <p onClick={() => onLink("learnMoreRef")} className="footer-link">
            LearnMore
          </p>
          <p
            onClick={() => onLink("exploreStoriesRef")}
            className="footer-link"
          >
            Explore Storiea
          </p>
        </div>
        <div className="social-media-all-icon-container">
          <div className="facebook-icon-wrap">
            <FaFacebookF className="facebook-icon" />
          </div>
          <div className="social-media-icon-wrap">
            <FaTwitter className="social-media-icon" />
          </div>
          <div className="social-media-icon-wrap">
            <FaYoutube className="social-media-icon" />
          </div>
          <div className="social-media-icon-wrap">
            <FaLinkedinIn className="social-media-icon" />
          </div>
        </div>
      </div>
      <div className="copyright-text-wrap">
        <p className="copyright-text">
          Copyright <FaRegCopyright /> FictionGenie {currentYear}
        </p>
      </div>
    </div>
  );
};
export default Footer;

const testimonials = [
  {
    text: "This is due to their excellent service, competitive pricing, and customer support. It's thoroughly refreshing to get such a personal touch.",
    author: "Jason Smith",
  },
  {
    text: "I've been extremely happy with their service. The team is professional, responsive, and always goes above and beyond to help.",
    author: "Emily Johnson",
  },
  {
    text: "Their products are top-notch, and their customer service is unparalleled. I highly recommend them!",
    author: "Michael Brown",
  },
  {
    text: "I've been a customer for years and have never been disappointed. Their attention to detail and commitment to quality are unmatched.",
    author: "Sarah Davis",
  },
  // {
  //   text: "I can't say enough good things about them. From start to finish, they made the process easy and stress-free.",
  //   author: "Alex Wilson",
  // },
  // {
  //   text: "Their team is always friendly and helpful. They really care about their customers and it shows.",
  //   author: "Jessica Lee",
  // },
  // {
  //   text: "I've recommended them to all my friends and family. Once you try their service, you'll never go anywhere else.",
  //   author: "David Martinez",
  // },
  // {
  //   text: "Their attention to detail and commitment to excellence sets them apart from the competition.",
  //   author: "Jennifer Taylor",
  // },
  // {
  //   text: "I've been a loyal customer for years and plan to continue for many more. They never disappoint.",
  //   author: "Robert Anderson",
  // },
  // {
  //   text: "Their service is second to none. I've never had a problem they couldn't solve quickly and efficiently.",
  //   author: "Maria Garcia",
  // },
  // {
  //   text: "I've been impressed with their professionalism and dedication to customer satisfaction. Keep up the great work!",
  //   author: "William Moore",
  // },
];
