import React, { useEffect, useRef } from "react";
import Header from "./header/header";
import Category from "./categories/categories";
import AboutUs from "./about-us/aboutUs";
import MultiLingual from "./multi-lingual-book/multiLingualBook";
import LearnMore from "./learn-more/learnMore";
import ListenReading from "./listen-reading/listenReading";
import ExploreStory from "./explore-stories/exploreStory";
import HowItWorks from "./how-it-works/howItWork";
import Faqs from "./faqs/faqs";
import OurFounder from "./our-founder/ourFounder";
import Footer from "./footer/footer";
import "react-multi-carousel/lib/styles.css";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

const LandingPage = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out-cubic",
      once: true,
    });
  }, []);

  const aboutUsRef = useRef(null);
  const categoriesRef = useRef(null);
  const learnMoreRef = useRef(null);
  const exploreStoriesRef = useRef(null);
  const headerRef = useRef(null);

  const scrollToAboutUs = (refrence) => {
    console.log("refrence", refrence);
    if (refrence === "aboutUsRef") {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (refrence === "categoriesRef") {
      categoriesRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (refrence === "learnMoreRef") {
      learnMoreRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (refrence === "exploreStoriesRef") {
      exploreStoriesRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (refrence === "headerRef") {
      headerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="main-div">
        <div id="header">
          <Header onLink={scrollToAboutUs} headerRef={headerRef}/>
        </div>
        <div id="body">
          <Category categoriesRef={categoriesRef} />
          <AboutUs aboutUsRef={aboutUsRef} />
          <MultiLingual />
          <LearnMore learnMoreRef={learnMoreRef} />
          <ListenReading />
          <ExploreStory exploreStoriesRef={exploreStoriesRef} />
          <HowItWorks />
          <Faqs/>
          <OurFounder />
          <Footer onLink={scrollToAboutUs} />
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
