import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BsArrowLeft, BsArrowRight, BsFillPlayFill } from 'react-icons/bs';
import { MdReplay } from 'react-icons/md';
import { AiOutlinePause } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import config from '../../Helpers/config.json';
import { RxCross2 } from 'react-icons/rx';
import { DraggableCore } from 'react-draggable';
import { Resizable } from 'react-resizable';
import { toast } from 'react-toastify';
import 'react-resizable/css/styles.css';
import './book.css';

const Book = () => {
  const location = useLocation();
  const bookData = location.state?.SelectedBook || null;
  const queryParams = new URLSearchParams(location.search);
  const userLanguage = queryParams.get('lang') || 'english';
  const [allLanguagesSupport, setAllLanguagesSupport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allStory, setAllStory] = useState([]);
  const [showStory, setShowStory] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [readData, setReadData] = useState('');
  const [endingIndex, setEndingIndex] = useState(0);
  const [highlightedText, setHighlightedText] = useState('');
  const [translationToolTip, setTranslationToolTip] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [selectedWord, setSelectedWord] = useState('');
  const [category, setCategory] = useState('');
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 200, height: 30 });
  const [isToolTipLoading, setIsToolTipLoading] = useState(false);
  const [apiResponse, setAPIResponse] = useState({});
  const [translatedText, setTranslatedText] = useState({});
  const [selectCounrty, setSelectedCountry] = useState('');
  const [allBookTextLang, setAllBookTextLang] = useState('english');

  const utteranceRef = useRef(null);
  const substringsRef = useRef([]);

  const [buttonState, setButtonState] = useState({
    listen: true,
    pause: false,
    resume: false,
  });

  useEffect(() => {
    if ('speechSynthesis' in window) window.speechSynthesis.cancel();
    let allLang = ['english', 'french', 'chinese', 'germany', 'hindi', 'urdu'];
    setAllLanguagesSupport(
      allLang.filter((lang) => lang !== userLanguage) || []
    );
    if (userLanguage === 'english') {
      getBookData();
    } else {
      axios
        .get(
          `${config['baseUrl']}/bookPageTranslation/${bookData?.id}/${userLanguage}`
        )
        .then(({ data: res }) => {
          if (res.result === 'success') {
            let tempStory = [];
            for (let story of res?.data) {
              const parser = new DOMParser();
              const doc = parser.parseFromString(
                story?.translation,
                'text/html'
              );
              const paragraphs = Array.from(doc.querySelectorAll('p')).map(
                (p) => p.textContent
              );
              const newPara = paragraphs.join('\n\n');
              tempStory.push({
                text: newPara,
                title: bookData?.title,
                ...story,
              });
            }
            let copystory = [...tempStory];
            setAllStory(tempStory);
            setShowStory(tempStory[0] || {});
            setReadData(
              copystory
                ?.splice(0, 1)
                ?.map((item) => item?.text)
                ?.join(' ') || ''
            );
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [userLanguage]);

  const getBookData = async () => {
    axios
      .get(`${config['baseUrl']}/getbookscontent/${bookData?.id}`)
      .then(({ data: res }) => {
        if (res.message === 'fetched') {
          let tempStory = [];
          for (let story of res?.data) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(story?.content, 'text/html');
            const paragraphs = Array.from(doc.querySelectorAll('p')).map(
              (p) => p.textContent
            );
            const newPara = paragraphs.join('\n\n');
            tempStory.push({
              text: newPara,
              title: bookData?.title,
              ...story,
            });
          }
          let copystory = [...tempStory];
          setAllStory(tempStory);
          setShowStory(tempStory[0] || {});
          setReadData(
            copystory
              ?.splice(0, 1)
              ?.map((item) => item?.text)
              ?.join(' ') || ''
          );
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    let startIndex = showStory?.text
      ?.split('.')
      .join('')
      .indexOf(highlightedText);
    let endIndex = startIndex + highlightedText?.length - 1;
    setEndingIndex(endIndex);
  }, [highlightedText, showStory]);

  const nextPage = () => {
    if (currentPage !== allStory.length - 1) {
      window.speechSynthesis.cancel();
      let temp = [...allStory];
      setHighlightedText('');
      setShowStory(temp[currentPage + 1] || {});
      setReadData(
        temp
          ?.splice(currentPage + 1, 1)
          ?.map((item) => item?.text)
          ?.join(' ') || ''
      );
      setButtonState({ ...buttonState, pause: false, listen: true });
      setCurrentPage((prev) => prev + 1);
    }
    return;
  };

  const prevPage = () => {
    if (currentPage !== 0) {
      window.speechSynthesis.cancel();
      let temp = [...allStory];
      setHighlightedText('');
      setShowStory(temp[currentPage - 1] || {});
      setReadData(
        temp
          ?.splice(currentPage - 1, 1)
          ?.map((item) => item?.text)
          ?.join(' ') || ''
      );
      setButtonState({ ...buttonState, pause: false, listen: true });
      setCurrentPage((prev) => prev - 1);
    }
    return;
  };

  const generalCall = () => {
    if (buttonState.listen) {
      speakText();
      setButtonState({ ...buttonState, listen: false, pause: true });
    } else if (buttonState.pause) {
      pauseSpeech();
      setButtonState({ ...buttonState, pause: false, resume: true });
    } else if (buttonState.resume) {
      resumeSpeech();
      setButtonState({ ...buttonState, pause: true, resume: false });
    }
  };

  const againPlay = () => {
    window.speechSynthesis.cancel();
    onSpeakText(0, '', true);
    setReadData(readData);
    setButtonState({
      ...buttonState,
      listen: true,
      pause: false,
      resume: false,
    });
  };

  const onSpeakText = (index, data, inititialCase = false) => {
    if (inititialCase) {
      return setHighlightedText(data);
    }
    setHighlightedText((e) => (e ? `${e}.${data}` : `${data}`));
  };

  const speakText = (data = '') => {
    if ('speechSynthesis' in window) {
      if (userLanguage === 'hindi') {
        substringsRef.current = readData?.split(',');
      } else if (userLanguage === 'chinese') {
        substringsRef.current = readData
          ?.split(/[。！？]/)
          ?.filter((sentence) => sentence.trim() !== '');
      } else if (userLanguage === 'urdu') {
        substringsRef.current = readData
          ?.split(/۔|\?|!/)
          ?.filter((sentence) => sentence.trim() !== '');
      } else {
        substringsRef.current = readData
          ?.split(/[.!?]/)
          ?.filter((sentence) => sentence.trim() !== '');
      }
      playNext(0);
    }
  };

  const playNext = (index) => {
    if (index >= substringsRef.current.length) {
      setHighlightedText('');
      setButtonState({ ...buttonState, pause: false, listen: true });
      return;
    }

    const substring = substringsRef.current[index];
    utteranceRef.current = new SpeechSynthesisUtterance(substring);

    utteranceRef.current.onstart = () => {
      onSpeakText(index + 1, substring);
    };

    utteranceRef.current.onend = () => {
      playNext(index + 1);
    };

    var voices = window.speechSynthesis.getVoices();
    var customVoice;
    if (userLanguage === 'french') {
      customVoice = voices.find((voice) => voice.name === 'Google français');
    } else if (userLanguage === 'hindi') {
      customVoice = voices.find((voice) => voice.name === 'Google हिन्दी');
    } else if (userLanguage === 'chinese') {
      customVoice = voices.find(
        (voice) => voice.name === 'Google 普通话（中国大陆）'
      );
    } else if (userLanguage === 'german') {
      customVoice = voices.find((voice) => voice.name === 'Google Deutsch');
    } else if (userLanguage === 'urdu') {
      customVoice = voices.find((voice) => voice.name === 'Google हिन्दी');
    } else {
      customVoice = voices.find(
        (voice) => voice.name === 'Google UK English Female'
      );
    }

    utteranceRef.current.voice = customVoice;

    utteranceRef.current.rate = 1.0;
    window.speechSynthesis.speak(utteranceRef.current);
  };

  const pauseSpeech = () => {
    if (utteranceRef.current) {
      window.speechSynthesis.pause();
    }
  };

  const resumeSpeech = () => {
    if (utteranceRef.current) {
      window.speechSynthesis.resume();
    }
  };


  const handleDrag = (e, { deltaX, deltaY }) => {
    setPosition((prevPosition) => ({
      x: prevPosition.x + deltaX,
      y: prevPosition.y + deltaY,
    }));
  };

  const handleResize = (e, { size }) => {
    setSize(size);
  };

  const handleTextSelection = () => {
    const selectedText = window.getSelection()?.toString();
    setSelectedWord(selectedText);
  };

  const validate = (type) => {
    if (!!selectedWord.length) {
      if (type === 'translation') {
        setTranslationToolTip(true);
      } else if (type === 'dictionary') {
        setTooltipVisible(true);
      }
    } else {
      toast.warn('Please select word', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const searchDetail = (type) => {
    setCategory(type);
    axios
      .get(`https://api.dictionaryapi.dev/api/v2/entries/en/${selectedWord}`)
      .then(function (response) {
        setAPIResponse(response.data[0]?.meanings?.[0] || {});
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const countrySelect = (counrtyName) => {
    if (!selectedWord) {
      toast.warn('Please select text', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else {
      setIsToolTipLoading(true);
      setSelectedCountry(counrtyName);
      axios
        .post(`${config['baseUrl']}/translateLanguageText`, {
          text: selectedWord,
          targetedLanguage: counrtyName?.toLowerCase(),
        })
        .then(function (response) {
          setTranslatedText(response?.data);
          setTranslationToolTip(true);
          setIsToolTipLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setIsToolTipLoading(false);
        });
    }
  };

  return (
    <div className='book-container'>
      <div className='left'>

        <div className='left-top'>
          <h1 className='head-story'> {showStory?.title}</h1>
          <div className='story-writter-container'>
            <p className='story-wriiter'>Written by</p>
            <p className='story-wriiter-name'>Allama Muhammad Iqbal</p>
          </div>
          {/* <div className='read-func-container'>
            <div className='replay-btn' onClick={againPlay}>
              <MdReplay size={30} color='white' />
            </div>
            <div className='resume-pause-btn' onClick={generalCall}>
              {buttonState.listen || buttonState.resume ? (
                <BsFillPlayFill size={30} color='white' />
              ) : (
                <AiOutlinePause size={30} color='white' />
              )}
            </div>
          </div> */}
          {isLoading ? (
            <div className='loading-spinner'>
              <Spinner animation='border' />
            </div>
          ) : (
            <div>
              {allStory.length === 0 ? (
                <div className='not-found-story'>
                  <p>No content found!</p>
                </div>
              ) : (
                <p className='text-story' onMouseUp={handleTextSelection}>
                  {' '}
                  <span style={{ backgroundColor: 'yellow' }}>
                    {highlightedText}{' '}
                  </span>{' '}
                  {!!highlightedText.length
                    ? showStory?.text?.slice(endingIndex + 2)
                    : showStory?.text?.slice(endingIndex + 1)}
                </p>
              )}
            </div>
          )}
        </div>
        <div className='left-bottom'>
          <div className='label'>
            {currentPage + 1 > 1 && (
              <BsArrowLeft
                size={20}
                className='arrow-icon'
                onClick={prevPage}
              />
            )}
            <span className='range'>
              {allStory.length === 0
                ? `${currentPage} of ${allStory.length}`
                : `${currentPage + 1} of ${allStory.length}`}
            </span>
            <BsArrowRight size={20} className='arrow-icon' onClick={nextPage} />{' '}
          </div>
          <div className='buttons'>
            <button
              className='round-button'
              onClick={() => validate('translation')}>
              Translate
            </button>
            <button
              className='round-button'
              onClick={() => validate('dictionary')}>
              Search in Dictionary
            </button>
          </div>
        </div>

      </div>
      <div className='right'>
        <img
          className='full-image'
          src={`${config['baseUrl']}${showStory?.image}`}
          alt='Full-size'
        />
      </div>
      {tooltipVisible && (
        <DraggableCore onDrag={handleDrag}>
          <Resizable
            width={size.width}
            height={size.height}
            onResize={handleResize}>
            <div
              className='tooltip-container customm-tooltip'
              style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
              }}>
              <span>
                <RxCross2
                  size={30}
                  className='icon-cross'
                  onClick={() => setTooltipVisible(false)}
                />
              </span>
              <div className='customm-tooltip-head'>
                <p className='m-0 mb-1 pd-2'>
                  {' '}
                  <span className='fw-bold pr-10'>Selected Word: </span>{' '}
                  {selectedWord}
                </p>
              </div>
              <div className='customm-tooltip-option'>
                <button
                  className={`btn-tooltip ${category === 'Synonyms' && 'selected-otp'
                    }`}
                  onClick={() => searchDetail('Synonyms')}>
                  Synonyms
                </button>
                <button
                  className={`btn-tooltip ${category === 'Antonym' && 'selected-otp'
                    }`}
                  onClick={() => searchDetail('Antonym')}>
                  Antonym
                </button>
                <button
                  className={`btn-tooltip ${category === 'Definition' && 'selected-otp'
                    }`}
                  onClick={() => searchDetail('Definition')}>
                  Definition
                </button>
              </div>
              <div className='customm-tooltip-response'>
                {category === 'Synonyms' && (
                  <div>
                    <p className='m-0 mt-2 pd-2 fw-bold'> Synonyms:</p>
                    <div className='sys-res-container'>
                      {apiResponse?.synonyms &&
                        apiResponse?.synonyms?.length === 0 && <p>Not found</p>}
                      {apiResponse?.synonyms?.map((item) => (
                        <p className='sys-res'>{item}</p>
                      ))}
                    </div>
                  </div>
                )}
                {category === 'Antonym' && (
                  <div>
                    <p className='m-0 mt-2 pd-2 fw-bold'> Antonym:</p>
                    <div className='sys-res-container'>
                      {apiResponse?.antonyms &&
                        apiResponse?.antonyms?.length === 0 && <p>Not found</p>}
                      {apiResponse?.antonyms?.map((item) => (
                        <p className='sys-res'>{item}</p>
                      ))}
                    </div>
                  </div>
                )}
                {category === 'Definition' && (
                  <div>
                    <p className='m-0 mt-2 pd-2 fw-bold'> Definition</p>
                    {apiResponse?.definitions &&
                      apiResponse?.definitions?.length === 0 && (
                        <p>not found</p>
                      )}
                    <div className='def-res-container'>
                      <ul>
                        {apiResponse?.definitions?.map((item) => (
                          <li className='m-0 def-res'>{item?.definition}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Resizable>
        </DraggableCore>
      )}
      {translationToolTip && (
        <DraggableCore onDrag={handleDrag}>
          <Resizable
            width={size.width}
            height={size.height}
            onResize={handleResize}>
            <div
              className='tooltip-container customm-tooltip translation-tooltip'
              style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
              }}>
              <span>
                <RxCross2
                  size={30}
                  className='icon-cross'
                  onClick={() => setTranslationToolTip(false)}
                />
              </span>
              <div className='customm-tooltip-head'>
                <p className='m-0 mb-1 pd-2'>
                  {' '}
                  <span className='fw-bold pr-10'>Selected Word: </span>{' '}
                </p>
                <p className='selected-text'>{selectedWord}</p>
              </div>
              <div className='customm-tooltip-option'>
                <p className='m-0 mt-2 mb-1 pd-2'>
                  {' '}
                  <span className='fw-bold pr-10'>Select Language: </span>{' '}
                </p>
                {allLanguagesSupport?.map((lang) => {
                  return (
                    <button
                      className={`btn-tooltip ${selectCounrty === `${lang}` && 'selected-otp'
                        }`}
                      onClick={() => countrySelect(lang)}>
                      {lang?.charAt(0)?.toUpperCase() + lang?.slice(1)}
                    </button>
                  );
                })}
              </div>
              {selectCounrty.length > 0 && (
                <div className='translation-box mt-2'>
                  <p className='m-0 fw-bold'>Transation:</p>
                  <p className='m-0 translation'>
                    {isToolTipLoading ? (
                      <Spinner className='pt-10' animation='border' />
                    ) : (
                      translatedText?.translation
                    )}
                  </p>
                </div>
              )}
            </div>
          </Resizable>
        </DraggableCore>
      )}
    </div>
  );
};

export default Book;
