import React, { useEffect } from "react";
import fifth_image from "../../../assets/parent-guide/image.png";
import "./style.css";

const LearnMore = ({learnMoreRef}) => {
  return (
    <div>
      <div className="guiding-parents-main-container" ref={learnMoreRef}>
        <div className="guiding-parents-container">
          <div className="guiding-parent-content-wrap" data-aos="flip-right">
            <div className="guiding-parent-hd-wrap">
              <p className="guiding-parent-hd">
                Guiding Parents with Powerful insights!
              </p>
              <p className="guiding-parent-text">
                FictionGenie, an Al-powered platform, not just provide parents
                with an assessment of their children's reading advancement, but
                also offers insight to enchance their creative skills
              </p>
              <button className="learn-more-button">Learn More</button>
            </div>
          </div>
          <div className="guiding-parent-img-wrap" data-aos="flip-left">
            <img className="guiding-parent-img" src={fifth_image} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LearnMore;
