import React from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import Account from '../Components/Account/Account'
import Profile from '../Components/Profile/Profile'
import Book from '../Components/Book/Book'
import Verfication from '../Components/Verification/Verfication'
import AccountSection from '../pages/account-section/AccountSection'
import SignupUser from '../pages/signup-user/SignupUser'
import LoginUser from '../pages/login-user/login-user'
import ForgotPasswordUser from '../pages/forgot-password-user/forgot-password-user'
import OtpUser from '../pages/otp-user/otp-user'
import ResetPasswordUser from '../pages/reset-password-user'
import NewDashboard from '../Components/mainDashboard/mainDashboard'
import Dashboard from '../Components/Dashboard/Dashboard.jsx'
import BookDetails from '../Components/BookDetails/bookDetails'
import AddProfile from '../pages/add-profile/add-profile.jsx'
import NotFound from '../pages/NotFound/NotFound.jsx'
import PasswordConfirmation from '../Components/PasswordConfirmation/PasswordConfirmation.jsx'
import Test from '../Test.js'
import LandingPage from '../pages/newlandingpage/landing-page.jsx'

const Routing = () => {
  const publicRoutes = [
    { path: '/', element: <LoginUser /> },
    { path: '/forgot-password', element: <ForgotPasswordUser /> },
    { path: '/signup', element: <SignupUser /> },
    { path: '/otp', element: <OtpUser /> },
    { path: '/verification', element: <Verfication /> },
    { path: '/signin', element: <LoginUser /> },
    { path: '/reset-password', element: <ResetPasswordUser /> },
  ]

  const privateRoutes = [
    { path: '/', element: <AccountSection /> },
    { path: '/add-profile', element: <AddProfile /> },
    { path: '/book-detail', element: <BookDetails /> },
    { path: '/dashboard-new', element: <NewDashboard /> },
    { path: '/dashboard', element: <Dashboard /> },
    { path: '/account', element: <Account /> },
    { path: '/profile', element: <Profile /> },
    { path: '/select-profile', element: <AccountSection /> },
    { path: '/book', element: <Book /> },
    { path: '/PasswordConfirmation', element: <PasswordConfirmation /> },
    { path: '/landing-page', element: <LandingPage /> },
    { path: '/new-landing-page', element: <LandingPage /> },
    // ... add other private routes
  ]

  const redirectRoutes = [
    '/forgot-password',
    '/signup',
    '/otp',
    '/verification',
    '/signin',
    '/reset-password',
  ]

  const ReDirect = () => {
    const navigate = useNavigate()
    React.useEffect(() => {
      navigate('/')
    }, [navigate])

    return null
  }
  const isUserAuthenticated = !!localStorage.getItem('token')
  return (
    <BrowserRouter>
      <Routes>
        {(isUserAuthenticated ? privateRoutes : publicRoutes).map((route) => (
          <Route key={route.path} {...route} />
        ))}

        {redirectRoutes.map((path) => (
          <Route key={path} path={path} element={<ReDirect />} />
        ))}

        <Route path="/test-synthesis" element={<Test />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Routing
