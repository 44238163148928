import React, { useState } from 'react';
import Book from '../../assets/book.png';
import './style.css'
const config = require('../../Helpers/config.json');

const BookListing = ({ continueReadData }) => {

    const [fadeClass, setFadeClass] = useState('fade-in');


    return (
        <div className='mt-5 book-listing'>
            <h4 className='heading stats-of-profile '>Books Read</h4>
            <table className='profile-books-read-wrap'>
                <tbody>
                    {continueReadData.map((book, index) => (
                        <tr key={index} className='profile-book-img-text-wrap'>
                            <td>
                                <div className={`mb-4 img-container profile-book-img-container ${fadeClass}`}>
                                    <div className="dashboard-img-book-cover dashboard-img-book-cover-responsive profile-book-img">
                                        <img src={`${config['baseUrl']}${book?.image}` || Book} alt={book.title} className='bookImage' />
                                        <div className="book-spine"></div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='content-wrap me-5'>
                                    <span className='title'>{book.title}</span>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className='profile-books-read-responsive'>
                <tbody>
                    {continueReadData.map((book, index) => (
                        <div>
                            <tr key={index} className='profile-book-img-text-wrap'>
                                <td>
                                    <div className={` img-container profile-book-img-container ${fadeClass}`}>
                                        <div className="dashboard-img-book-cover dashboard-img-book-cover-responsive profile-book-img">
                                            <img src={`${config['baseUrl']}${book?.image}` || Book} alt={book.title} className='bookImage' />
                                            <div className="book-spine"></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='content-wrap'>
                                        <span className='title'>{book.title}</span>
                                    </div>
                                </td>
                            </tr>
                        </div>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BookListing;
