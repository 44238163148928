import React from "react";
import eight_image_1 from "../../../assets/how it works/1.png";
import eight_image_a from "../../../assets/how it works/1a.png";
import eight_image_2 from "../../../assets/how it works/2.png";
import eight_image_2a from "../../../assets/how it works/2a.png";
import eight_image_3 from "../../../assets/how it works/3.png";
import eight_image_3a from "../../../assets/how it works/3a.png";
import eight_image_4 from "../../../assets/how it works/4.png";
import "./style.css"

const HowItWork = () => {
  return (
    <div>
      <div className="works-container">
        <div className="works-content-wrap" data-aos="fade-down">
          <p className="works-content-hd">How it works</p>
          <p className="works-text">
            Connect your kids with stories from Al-powered platform with
            illustration and feedback that help to improve reading and
            creativity skills.
          </p>
        </div>
        <div className="works-img-container" data-aos="fade-up">
          <div className="works-img-wrap">
            <div className="story-time-img-container">
              <img src={eight_image_a} className="story-time-name-frame" />
              <img src={eight_image_1} className="story-time-tablet" />
            </div>
            <div className="magic-reading-img-container">
              <img src={eight_image_2a} className="magic-reading-name-frame" />
              <img src={eight_image_2} className="magic-reading-tablet" />
            </div>
            <div className="movie-mind-img-container">
              <img src={eight_image_3a} className="movie-name-frame" />
              <img src={eight_image_3} className="movie-tablet" />
            </div>
            <div className="magic-reading-img-container">
              <img src={eight_image_4} className="magic-reading-tablet" />
              <img src={eight_image_2a} className="magic-reading-name-frame" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowItWork;
