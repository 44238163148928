import React, { useState } from 'react';
import logo from '../../img/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import right_img from '../../img/right-side-img.png';
import right_back from '../../img/right-bg.png';
const config = require('../../Helpers/config.json');

const Verification = () => {
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const email = location?.state?.email;
  const navigate = useNavigate();

  const verifyOtp = () => {
    fetch(`${config['baseUrl']}/auth/otp/parent/OTPResponseParents `, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        email: email,
        otp: otp,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.message === 'verfied otp') {
          navigate('/reset-password', { state: { email } });
        } else {
          alert(res.message);
        }
      });
  };

  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-6 mt-5'>
            <div className='row my-5'>
              <div className='col-md-12'>
                <div className='logo-container d-flex align-items-center justify-content-start'>
                  <img src={logo} className='img-fluid logo-img' alt='' />
                  <h5 className='fw-bold ms-2 mt-2'>FictionGenie</h5>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <h1 className='fw-medium fs-60'>
                  Explore magic with FictionGenie!
                </h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8 z-2'>
                <input
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder='OTP code'
                  type='text'
                  className='form-control rounded-2'
                />
              </div>
              <div className='col-md-8'></div>
            </div>
            <div className='row mt-3'>
              <div className='col-md-12 z-2 mb-5 d-md-none d-lg-block'>
                <div className='d-flex '>
                  <button
                    onClick={verifyOtp}
                    className='btn btn text-white rounded-2 bg-orange'>
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='right-img position-relative'>
              <img src={right_img} className='img-fluid  rounded-2' alt='' />
              <div className='right-img-back'>
                <img src={right_back} className='img-fluid rounded-2' alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
