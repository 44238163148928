import React, { useEffect, useRef, useState } from "react";
import second_child_1 from "../../../assets/what reader/second-sec-1.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.css";
import axios from "axios";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
const config = require("../../../Helpers/config.json");

const Categories = ({ categoriesRef }) => {
  // states
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [selectedCatName, setSelectedCatName] = React.useState("");
  const [categoryArray, setCategoryArray] = React.useState([]);
  const [disabilityChevronLeft, setDisabilityChevronLeft] = React.useState([]);
  const [disabilityChevronRight, setDisabilityChevronRight] = React.useState(
    []
  );
  const [showRightArrowForGirlPowerData, setShowRightArrowForGirlPowerData] =
    useState(false);
  const [arrowDisableLeft, setArrowDisableLeft] = useState({
    first: false,
  });
  const [arrowDisableRight, setArrowDisableRight] = useState({
    first: false,
  });

  // refrences

  const carouselRef = useRef(null);
  const elementRef = useRef(); // carousel

  // useEffects
  React.useEffect(() => {
    fetchData();
  }, []);
  React.useEffect(() => {
    const filterArray = data.filter(
      (item) => item?.bookCategoryName === selectedCatName
    );
    setFilteredData(filterArray);
  }, [selectedCatName]);
  useEffect(() => {
    const containerWidth = carouselRef?.current?.scrollWidth;
    const cardWidth = 236;
    const totalWidth = filteredData?.length * cardWidth;
    setShowRightArrowForGirlPowerData(totalWidth > containerWidth);
  }, [filteredData]);

  // functions
  const fetchData = () => {
    axios
      .get(`${config["baseUrl"]}/getCategoryBooks`)
      .then((response) => {
        setData(response.data.data || []);
        let uniqueName = [];
        response.data.data.filter((item) => {
          if (!uniqueName.includes(item?.bookCategoryName)) {
            uniqueName.push(item?.bookCategoryName);
            return true;
          }
        });
        setCategoryArray(uniqueName);
        setSelectedCatName(uniqueName[0] || "");
      })
      .catch((err) => {
        console.log("Error while fetching data from api: ", err);
      });
  };

  const handleScroll = (category, element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);

      const isScrollAtEnd =
        element.scrollLeft + element.clientWidth >= element.scrollWidth;

      if (scrollAmount >= distance || isScrollAtEnd) {
        clearInterval(slideTimer);
      }
      // element.scrollLeft === 0
      // isScrollAtEnd

      if (element.scrollLeft === 0) {
        setDisabilityChevronLeft(true);
      } else {
        setDisabilityChevronLeft(false);
      }
      if (isScrollAtEnd) {
        setDisabilityChevronRight(true);
      } else {
        setDisabilityChevronRight(false);
      }

      setArrowDisableF(false, false, category);
    }, speed);
  };

  const setArrowDisableF = (isLeftDisabled, isRightDisabled, category) => {
    if (category === "first") {
      setArrowDisableLeft((prevState) => ({
        ...prevState,
        first: isLeftDisabled,
      }));
      setArrowDisableRight((prevState) => ({
        ...prevState,
        first: isRightDisabled,
      }));
    }
  };

  return (
    <div>
      <div className="carousel-main-container" ref={categoriesRef}>
        <div>
          <div className="carousel-hd-wrap" data-aos="fade-down">
            <p className="carosuel-hd">What type of reader is your Child?</p>
          </div>
        </div>
        <div className="categories-name-container" data-aos="fade-down">
          <div className="categories-name-wrap">
            {categoryArray?.map((name, i) => (
              <button
                key={i}
                className={`category-name ${
                  name === selectedCatName ? "selected" : ""
                }`}
                onClick={() => setSelectedCatName(name)}
              >
                {name}
              </button>
            ))}
          </div>
        </div>
        <div className="courousel-main-wrap" data-aos="fade-up">
          <div className="scroll-container" ref={carouselRef}>
            {!arrowDisableLeft?.first && (
              <div
                className={`category-arrow-wrap category-left-arrow ${
                  disabilityChevronLeft ? "disable" : ""
                }`}
                onClick={() =>
                  handleScroll("first", elementRef.current, 10, 250, -10)
                }
              >
                <FaChevronLeft className="category-arrow" />
              </div>
            )}
            <div className={`all-cards carousel-wrap`} ref={elementRef}>
              {filteredData?.map((item, index) => {
                return (
                  <div className="card-container" key={index}>
                    <div className="book-cover">
                      <img
                        src={`${config["baseUrl"]}${item?.bookImage}`}
                        alt=""
                        className="card-img"
                      />
                      <div className="book-spine"></div>
                    </div>
                    <p className="dashboard-img-hd category-book-title">
                      {item?.bookTitle}
                    </p>
                  </div>
                );
              })}
            </div>
            {!arrowDisableRight?.first && showRightArrowForGirlPowerData && (
              <div
                className={`category-arrow-wrap category-right-arrow ${
                  disabilityChevronRight ? "disable" : ""
                }`}
                onClick={() =>
                  handleScroll("first", elementRef.current, 10, 250, 10)
                }
              >
                <FaChevronRight className="category-arrow" />
              </div>
            )}
          </div>
        </div>
        <div className="see-category-btn-wrap" data-aos="fade-up">
          <button className="see-category-btn">See Category</button>
        </div>
      </div>
    </div>
  );
};
export default Categories;
